var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { atom } from 'jotai';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOutFirebase, } from '../../../firebase';
import { apiClientAtom } from '../../../store';
import { removeCookie } from '../../../utils';
export var userAtom = atom(undefined);
export var createUserAtom = atom(null, function (get, set, _a) { return __awaiter(void 0, void 0, void 0, function () {
    var hyphenRemoved, credential, idToken, payloadWithHeaders, user, userWithReservations;
    var _b;
    var email = _a.email, password = _a.password, postal_code = _a.postal_code, payload = __rest(_a, ["email", "password", "postal_code"]);
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                hyphenRemoved = (_b = postal_code === null || postal_code === void 0 ? void 0 : postal_code.replace('-', '')) !== null && _b !== void 0 ? _b : null;
                return [4 /*yield*/, createUserWithEmailAndPassword(email, password)];
            case 1:
                credential = _c.sent();
                return [4 /*yield*/, credential.user.getIdToken()];
            case 2:
                idToken = _c.sent();
                payloadWithHeaders = {
                    body: __assign({ email: email, postal_code: hyphenRemoved }, payload),
                    config: { headers: { Authorization: "Bearer ".concat(idToken) } },
                };
                return [4 /*yield*/, get(apiClientAtom).users.$post(payloadWithHeaders)];
            case 3:
                user = _c.sent();
                userWithReservations = __assign(__assign({}, user), { reservations: [] });
                set(userAtom, userWithReservations);
                return [2 /*return*/, userWithReservations];
        }
    });
}); });
export var updateUserAtom = atom(null, function (get, set, _a) { return __awaiter(void 0, void 0, void 0, function () {
    var hyphenRemoved, updatedUser;
    var _b;
    var id = _a.id, postal_code = _a.postal_code, payload = __rest(_a, ["id", "postal_code"]);
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                hyphenRemoved = (_b = postal_code === null || postal_code === void 0 ? void 0 : postal_code.replace('-', '')) !== null && _b !== void 0 ? _b : null;
                return [4 /*yield*/, get(apiClientAtom)
                        .users._id(id)
                        .$patch({
                        body: __assign(__assign({}, payload), { postal_code: hyphenRemoved }),
                    })];
            case 1:
                updatedUser = _c.sent();
                set(userAtom, function (prev) { return (prev ? __assign(__assign({}, prev), updatedUser) : undefined); });
                return [2 /*return*/, __assign({ id: id }, payload)];
        }
    });
}); });
// ユーザーに紐づく予約情報の更新
export var updateUserReservationAtom = atom(null, function (get, set, _a) { return __awaiter(void 0, void 0, void 0, function () {
    var updatedReservation;
    var id = _a.id, payload = __rest(_a, ["id"]);
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, get(apiClientAtom)
                    .reservations._id(id)
                    .$patch({ body: payload })];
            case 1:
                updatedReservation = _b.sent();
                set(userAtom, function (prev) {
                    if (!prev)
                        return undefined;
                    var reservations = prev.reservations.map(function (reservation) {
                        console.info(id, reservation, updatedReservation);
                        return reservation.id === id
                            ? __assign(__assign({}, reservation), updatedReservation) : reservation;
                    });
                    return __assign(__assign({}, prev), { reservations: reservations });
                });
                return [2 /*return*/, updatedReservation];
        }
    });
}); });
export var signInWithEmailAtom = atom(null, function (_1, __1, _a) { return __awaiter(void 0, [_1, __1, _a], void 0, function (_, __, _b) {
    var user;
    var email = _b.email, password = _b.password;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, signInWithEmailAndPassword(email, password)];
            case 1:
                user = (_c.sent()).user;
                return [2 /*return*/, user];
        }
    });
}); });
export var loginAtom = atom(null, function (get, set, idToken) { return __awaiter(void 0, void 0, void 0, function () {
    var config, userPromise, reservationsPromise, _a, user, reservations, userWithReservations;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                config = { headers: { Authorization: "Bearer ".concat(idToken) } };
                userPromise = get(apiClientAtom).login.$get({
                    config: config,
                });
                reservationsPromise = get(apiClientAtom).reservations.$get({
                    config: config,
                });
                return [4 /*yield*/, Promise.all([
                        userPromise,
                        reservationsPromise,
                    ])];
            case 1:
                _a = _b.sent(), user = _a[0], reservations = _a[1];
                userWithReservations = __assign(__assign({}, user), { reservations: reservations });
                set(userAtom, userWithReservations);
                return [2 /*return*/];
        }
    });
}); });
export var logoutAtom = atom(null, function (_, set) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, signOutFirebase()];
            case 1:
                _a.sent();
                removeCookie('token');
                removeCookie('refresh_token');
                set(userAtom, undefined);
                return [2 /*return*/];
        }
    });
}); });
