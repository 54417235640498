var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useSearchParams } from 'next/navigation';
import { useState } from 'react';
import { getCurrentFirebaseUser, verifyEmail, getEmailVerificationErrorMessage, } from '../../../firebase';
import { useApi, useSnackbar } from '../../../hooks';
import { useSentry } from '../../error';
export var useEmailVerification = function () {
    var pushErrorToSentry = useSentry().pushErrorToSentry;
    var searchParams = useSearchParams();
    var oobCode = searchParams.get('oobCode');
    var _a = useSnackbar(), notifyError = _a.notifyError, openSnackbar = _a.openSnackbar;
    var api = useApi().api;
    var currentUser = getCurrentFirebaseUser();
    var _b = useState(''), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = useState(false), emailVerified = _c[0], setEmailVerified = _c[1];
    var sendEmailVerificationAndNotify = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!currentUser) {
                        return [2 /*return*/, openSnackbar('ログインが必要です', 'error')];
                    }
                    if (currentUser.emailVerified) {
                        return [2 /*return*/, openSnackbar('このメールアドレスはすでに認証されています', 'error')];
                    }
                    payload = {
                        email: (_a = currentUser.email) !== null && _a !== void 0 ? _a : '',
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.verify_email.$post({
                            body: payload,
                        })];
                case 2:
                    _b.sent();
                    openSnackbar('メールを再送信しました', 'success');
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    notifyError(error_1);
                    pushErrorToSentry({
                        category: 'Authentication',
                        payload: payload,
                        error: error_1,
                        message: 'メールの再送信に失敗しました',
                    });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var verifyEmailByOobCode = function () { return __awaiter(void 0, void 0, void 0, function () {
        var firebaseUser, error_2, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, refreshFirebaseUser()];
                case 1:
                    _a.sent();
                    firebaseUser = getCurrentFirebaseUser();
                    if (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.emailVerified)
                        return [2 /*return*/];
                    if (!oobCode) {
                        openSnackbar('認証コードが不正です', 'error');
                        return [2 /*return*/];
                    }
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, verifyEmail(oobCode)];
                case 3:
                    _a.sent();
                    setEmailVerified(true);
                    return [3 /*break*/, 5];
                case 4:
                    error_2 = _a.sent();
                    message = getEmailVerificationErrorMessage(error_2);
                    setEmailVerified(false);
                    setErrorMessage(message);
                    pushErrorToSentry({
                        category: 'Authentication',
                        payload: { firebaseUser: firebaseUser },
                        error: error_2,
                        message: message,
                    });
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handlePushToEmailVerificationPage = function () { return __awaiter(void 0, void 0, void 0, function () {
        var firebaseUser;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    firebaseUser = getCurrentFirebaseUser();
                    if (!firebaseUser)
                        return [2 /*return*/];
                    return [4 /*yield*/, (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.reload())];
                case 1:
                    _a.sent();
                    if (!(firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.emailVerified)) {
                        openSnackbar('メールアドレスの認証が完了していません', 'error');
                        return [2 /*return*/];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var refreshFirebaseUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var firebaseUser;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    firebaseUser = getCurrentFirebaseUser();
                    if (!firebaseUser) return [3 /*break*/, 2];
                    return [4 /*yield*/, firebaseUser.reload()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    return {
        email: currentUser === null || currentUser === void 0 ? void 0 : currentUser.email,
        isReady: !!currentUser,
        handlePushToEmailVerificationPage: handlePushToEmailVerificationPage,
        sendEmailVerificationAndNotify: sendEmailVerificationAndNotify,
        verified: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.emailVerified) || emailVerified,
        errorMessage: errorMessage,
        verifyEmailByOobCode: verifyEmailByOobCode,
        refreshFirebaseUser: refreshFirebaseUser,
    };
};
