import 'dayjs/locale/ja';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.locale('ja');
dayjs.tz.setDefault('Asia/Tokyo');
var formatDate = function (date, type) {
    if (!date)
        return '';
    if (type === 'YYYY-MM-DD HH:mm') {
        return dayjs(date).format('YYYY-MM-DD HH:mm');
    }
    else if (type === 'YYYY/MM/DD') {
        return dayjs(date).format('YYYY/MM/DD');
    }
    else if (type === 'YYYY/MM/DD HH:mm') {
        return dayjs(date).format('YYYY/MM/DD HH:mm');
    }
    else if (type === 'MMDD') {
        return dayjs(date).format('MM/DD');
    }
    else if (type === 'MM/DD(ddd)') {
        return dayjs(date).format('MM/DD(ddd)');
    }
    else if (type === 'MM/DD(ddd) HH:mm') {
        return dayjs(date).format('MM/DD(ddd) HH:mm');
    }
    else if (type === 'YYYY/MM/DD(ddd) HH:mm') {
        return dayjs(date).format('YYYY/MM/DD(ddd) HH:mm');
    }
    else if (type === 'HH:mm') {
        return dayjs(date).format('HH:mm');
    }
    else if (type === 'D') {
        return dayjs(date).format('D');
    }
    else {
        return dayjs(date).format('YYYY-MM-DD');
    }
};
var calcDaysCountOfMonth = function (date) {
    var startDate = dayjs(date).startOf('month');
    var endDate = dayjs(date).endOf('month');
    var numberOfMonthDays = endDate.diff(startDate, 'day') + 1;
    return numberOfMonthDays;
};
var isOverlapDates = function (dateStart, dateEnd, comparisonDateStart, comparisonDateEnd) {
    var inputStart = dayjs(dateStart);
    var inputEnd = dayjs(dateEnd);
    var existingStart = dayjs(comparisonDateStart);
    var existingEnd = dayjs(comparisonDateEnd);
    return inputStart.isBefore(existingEnd) && existingStart.isBefore(inputEnd);
};
export var getDateDiffFromDay = function (date) {
    var today = dayjs().startOf('day');
    var inputDate = dayjs(date);
    var diff = inputDate.diff(today, 'day');
    return diff;
};
var parseCalendarDate = function (date) {
    var day = dayjs(date);
    return {
        day: day.format('D'),
        date: day.format('YYYY-MM-DD'),
        MMDD: day.format('MM/DD'),
        weekday: day.format('(ddd)'),
    };
};
var getWeekdays = function (baseDate) {
    var date = dayjs(baseDate);
    var weekdays = Array.from({ length: 7 }, function (_, i) {
        var day = date.add(i, 'day');
        return {
            day: day.format('D'),
            date: day.format('YYYY-MM-DD'),
            MMDD: day.format('MM/DD'),
            weekday: day.format('(ddd)'),
        };
    });
    return {
        startAt: weekdays[0].date,
        endAt: weekdays[6].date,
        weekdays: weekdays,
    };
};
var minutesToTime = function (minutes) {
    var day = dayjs().startOf('day');
    var time = day.add(minutes, 'minute');
    return time.format('HH:mm');
};
var separateDateAndTime = function (date) {
    if (!date)
        return {
            year: '',
            month: '',
            day: '',
            time: '',
        };
    var datetime = dayjs(date).format('YYYYMMDD HH:mm');
    var year = datetime.slice(0, 4);
    var month = datetime.slice(4, 6);
    var day = datetime.slice(6, 8);
    var time = datetime.slice(9, 14);
    return {
        year: year,
        month: month,
        day: day,
        time: time,
    };
};
var calculateAge = function (birthday) {
    var today = dayjs();
    var birth = dayjs(birthday);
    var age = today.diff(birth, 'year');
    return age;
};
// 0 ~ 15分, 15 ~ 30分, 30 ~ 45分, 45 ~ 60分 の範囲で切り捨てる
var truncateToQuarterHour = function (dateString) {
    if (!dateString)
        return '';
    var date = dayjs(dateString);
    var minutes = date.minute();
    var truncatedMinutes = Math.floor(minutes / 15) * 15;
    var truncatedDate = date.minute(truncatedMinutes).second(0).millisecond(0);
    return truncatedDate.format('YYYY-MM-DD HH:mm'); // 切り捨てられた日付を文字列として返す
};
var isSameTime = function (date1, date2) {
    var time1 = dayjs(date1).format('HH:mm:ss');
    var time2 = dayjs(date2).format('HH:mm:ss');
    return time1 === time2;
};
export { dayjs as appDayjs, calcDaysCountOfMonth, formatDate, getWeekdays, isOverlapDates, minutesToTime, parseCalendarDate, separateDateAndTime, calculateAge, truncateToQuarterHour, isSameTime, };
