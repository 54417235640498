import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Snackbar, useMediaQuery, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { SnackbarContext, isAxiosError } from 'shared';
import { FlexCol } from '../components/mui/Box';
export const SnackbarProvider = ({ children }) => {
    const matches = useMediaQuery('(min-width:600px)');
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState({
        text: '',
    });
    const [severity, setSeverity] = useState('success');
    const openSnackbar = (message, severity, link) => {
        setIsOpen(true);
        setMessage({
            text: message,
            link: link,
        });
        setSeverity(severity);
    };
    const invalidNotification = () => {
        openSnackbar('入力内容に誤りがあります', 'error');
    };
    const notifyError = (error) => {
        if (isAxiosError(error)) {
            if (error.response) {
                const message = getErrorMessageFromStatus(error.response.status);
                openSnackbar(message, 'error');
            }
            else {
                openSnackbar('ネットワークエラーが発生しました。', 'error');
            }
        }
        else if (error instanceof Error) {
            console.error('error.stack', error.stack);
        }
        else {
            openSnackbar('エラーが発生しました。', 'error');
        }
    };
    const closeSnackbar = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsOpen(false);
    };
    return (_jsxs(SnackbarContext.Provider, { value: { openSnackbar, invalidNotification, notifyError }, children: [_jsx(Snackbar, { anchorOrigin: {
                    vertical: matches ? 'top' : 'top',
                    horizontal: 'center',
                }, open: isOpen, autoHideDuration: 7000, onClose: closeSnackbar, children: _jsx(Alert, { severity: severity, children: _jsxs(FlexCol, { gap: 1, children: [_jsx(Typography, { children: message.text }), message.link && (_jsx(Link, { href: message.link.url, target: "_blank", rel: "noopener noreferrer", color: "inherit", underline: "always", sx: {
                                    color: '#2196F3',
                                }, children: message.link.label }))] }) }) }), children] }));
};
const getErrorMessageFromStatus = (status) => {
    switch (status) {
        case 400:
            return '不正なリクエストです。';
        case 401:
            return '認証に失敗しました。再度ログインしてください。';
        case 403:
            return 'アクセス権限がありません。';
        case 404:
            return 'リソースが見つかりません。';
        case 500:
            return 'サーバーで問題が発生しました。';
        default:
            return '未知のエラーが発生しました。';
    }
};
