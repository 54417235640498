import useAspidaSWR from '@aspida/swr';
import { useApi } from './useApi';
export var useCalendarState = function (_a) {
    var ccid = _a.ccid, clinicAlias = _a.clinicAlias, date = _a.date, isOnline = _a.isOnline, _b = _a.mode, mode = _b === void 0 ? 'day' : _b;
    var api = useApi().api;
    var isSelectedCC = !!ccid && !!clinicAlias;
    var _c = useAspidaSWR(api.list_slots
        ._url_alias(clinicAlias !== null && clinicAlias !== void 0 ? clinicAlias : '')
        ._ccid(ccid !== null && ccid !== void 0 ? ccid : '')
        ._date(date !== null && date !== void 0 ? date : '')
        ._mode(mode !== null && mode !== void 0 ? mode : ''), '$get', {
        refreshInterval: isOnline ? 1000 * 60 * 1 : undefined,
        key: isSelectedCC ? [clinicAlias, ccid, date, mode] : undefined,
    }), slotList = _c.data, error = _c.error, isLoading = _c.isLoading;
    return { slotList: slotList, isLoading: isLoading, error: error };
};
