import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const AppLayout = ({ children, header, maxWidth = 600, }) => {
    return (_jsxs("div", { style: {
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            minHeight: '100dvh',
            backgroundColor: '#F7FAFC',
        }, children: [header, _jsx("div", { style: {
                    display: 'flex',
                    justifyContent: 'center',
                    height: 'calc(100dvh - 103px)',
                    flexGrow: 1,
                    padding: '8px 0',
                    overflowY: 'auto',
                }, children: _jsx("div", { style: { maxWidth, width: '100%' }, children: children }) })] }));
};
