'use client';
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { app, contentsPadding } from 'shared';
import { AppLoading } from '../components';
export const AuthGuard = ({ children, unauthorizedRoutes, redirectWhenAuthorizedPaths = [], headerHeight, isAuthorized, currentPath, login, logout, withFirebase = true, }) => {
    const [isLoading, setIsLoading] = useState(withFirebase);
    useEffect(() => {
        if (!withFirebase)
            return;
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, (user) => __awaiter(void 0, void 0, void 0, function* () {
            if (user) {
                const idToken = yield user.getIdToken();
                yield (login === null || login === void 0 ? void 0 : login(idToken));
                setIsLoading(false);
            }
            else {
                yield (logout === null || logout === void 0 ? void 0 : logout());
                setIsLoading(false);
            }
        }));
        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const requireAuth = !unauthorizedRoutes.includes(currentPath);
    const shouldRedirectToLogin = requireAuth && !isAuthorized;
    const shouldRedirectWhenAuthorized = isAuthorized && redirectWhenAuthorizedPaths.includes(currentPath);
    return (_jsx(HandleLoading, { isLoading: isLoading, shouldRedirectWhenAuthorized: shouldRedirectWhenAuthorized, headerHeight: headerHeight, shouldRedirectToLogin: shouldRedirectToLogin, children: children }));
};
const HandleLoading = ({ children, headerHeight, isLoading, shouldRedirectToLogin, shouldRedirectWhenAuthorized, }) => {
    if (isLoading || shouldRedirectToLogin || shouldRedirectWhenAuthorized) {
        return (_jsx(AppLoading, { boxProps: {
                height: `calc(100vh - ${headerHeight + contentsPadding * 2}px)`,
            } }));
    }
    else {
        return _jsx(_Fragment, { children: children });
    }
};
