var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/system';
import { sleep, useDisclosure } from 'shared';
import { AppLoadingButton, ContainedButton, Flex } from '../mui';
const StyledDialog = styled(Dialog)(() => ({
    '& .MuiDialog-paper': {
        borderRadius: '16px',
        boxShadow: '0 10px 40px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(145deg, #ffffff, #f8f9fa)',
        border: 'none',
        overflow: 'hidden',
    },
}));
const DialogHeader = styled('div')(() => ({
    padding: '24px 32px',
    borderBottom: '1px solid #e0e0e0',
}));
const StyledDialogContent = styled(DialogContent)(() => ({
    padding: '32px',
}));
const DialogFooter = styled('div')(() => ({
    padding: '24px 32px',
    borderTop: '1px solid #e0e0e0',
}));
const StyledButton = styled(AppLoadingButton)(() => ({
    borderRadius: '8px',
    padding: '10px 24px',
    textTransform: 'none',
    fontWeight: 600,
    boxShadow: 'none',
    transition: 'all 0.3s ease',
    '&:hover': {
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        transform: 'translateY(-2px)',
    },
}));
export const AppDialog = ({ buttonProps = { sx: {} }, cancelLabel, children, maxWidth = 'sm', onCancel, onSave, disabledSave = false, saveLabel, title, triggerElement, loadingUntilRedirect, saveButton, disabled, saveButtonProps, }) => {
    const { getButtonProps, getDisclosureProps, onClose } = useDisclosure();
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        if (loadingUntilRedirect) {
            onSave === null || onSave === void 0 ? void 0 : onSave();
            return sleep(10000);
        }
        else {
            return onSave === null || onSave === void 0 ? void 0 : onSave(onClose);
        }
    });
    const handleCancel = () => __awaiter(void 0, void 0, void 0, function* () {
        onCancel === null || onCancel === void 0 ? void 0 : onCancel();
        onClose();
    });
    const { sx: buttonSx } = buttonProps, restButtonProps = __rest(buttonProps, ["sx"]);
    return (_jsxs(_Fragment, { children: [_jsx(ContainedButton, Object.assign({}, getButtonProps(), restButtonProps, { sx: Object.assign(Object.assign({}, buttonSx), { borderRadius: 2 }), disabled: disabled, children: triggerElement })), _jsxs(StyledDialog, Object.assign({}, getDisclosureProps(), { "aria-labelledby": "custom-dialog-title", maxWidth: maxWidth, fullWidth: true, children: [_jsx(DialogHeader, { children: _jsx(Typography, { variant: "h6", fontWeight: "bold", children: title }) }), _jsx(StyledDialogContent, { children: children }), _jsx(DialogFooter, { children: _jsxs(Flex, { justifyContent: "flex-end", gap: 2, children: [onCancel && (_jsx(StyledButton, { color: "secondary", onClick: handleCancel, variant: "outlined", children: cancelLabel })), saveButton
                                    ? saveButton
                                    : onSave && (_jsx(StyledButton, Object.assign({}, saveButtonProps, { onClick: handleSave, autoFocus: true, color: "primary", disabled: disabledSave, children: saveLabel })))] }) })] }))] }));
};
