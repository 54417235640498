var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Fade, Paper, Popper, TableCell, Typography } from '@mui/material';
import { styled } from '@mui/system';
export const DescriptionCell = ({ children, maxWidth, minWidth, }) => {
    return (_jsx(TableCell, { sx: {
            maxWidth,
            minWidth,
            cursor: 'default',
        }, children: _jsx(Typography, { sx: {
                wordBreak: 'break-all',
            }, children: children }) }));
};
const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: theme.spacing(2),
    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.1)',
    background: 'linear-gradient(145deg, #ffffff, #f8f9fa)',
    border: '1px solid #e0e0e0',
    padding: theme.spacing(3),
    maxWidth: 400,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
}));
export const PopperWithTransition = (_a) => {
    var { children, disablePortal = true, paperProps } = _a, popperProps = __rest(_a, ["children", "disablePortal", "paperProps"]);
    return (_jsx(Popper, Object.assign({}, popperProps, { transition: true, style: { zIndex: 100 }, placement: "auto", disablePortal: disablePortal, children: ({ TransitionProps }) => (_jsx(Fade, Object.assign({}, TransitionProps, { timeout: 300, children: _jsx(StyledPaper, Object.assign({}, paperProps, { sx: (paperProps === null || paperProps === void 0 ? void 0 : paperProps.sx)
                    ? Object.assign({ p: 2 }, paperProps.sx) : { p: 2 }, children: children })) }))) })));
};
