var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { zodResolver } from '@hookform/resolvers/zod';
import { useSearchParams } from 'next/navigation';
import { useForm } from 'react-hook-form';
import { confirmPasswordReset, signInWithEmailAndPassword, } from '../../../firebase';
import { useSnackbar } from '../../../hooks';
import { getUserSchema, z } from '../../../validation';
import { useSentry } from '../../error';
export var useResetPassword = function (onSuccess) {
    var pushErrorToSentry = useSentry().pushErrorToSentry;
    var _a = useSnackbar(), invalidNotification = _a.invalidNotification, openSnackbar = _a.openSnackbar;
    var searchParams = useSearchParams();
    var userSchema = getUserSchema();
    // TODO: i18n対応
    var _b = useForm({
        resolver: zodResolver(userSchema
            .pick({ password: true, passwordConfirmation: true })
            .superRefine(function (data, ctx) {
            if (data.password !== data.passwordConfirmation) {
                ctx.addIssue({
                    path: ['passwordConfirmation'],
                    code: z.ZodIssueCode.custom,
                    message: 'パスワードが一致しません',
                });
            }
        })),
        defaultValues: {
            password: '',
            passwordConfirmation: '',
        },
    }), control = _b.control, handleSubmit = _b.handleSubmit;
    var submit = function (email) {
        return handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var oobCode, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        oobCode = searchParams.get('oobCode');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        if (!oobCode)
                            return [2 /*return*/];
                        return [4 /*yield*/, confirmPasswordReset(oobCode, data.password)];
                    case 2:
                        _a.sent();
                        openSnackbar('パスワードの変更が完了しました', 'success');
                        onSuccess();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        openSnackbar('パスワードの変更に失敗しました', 'error');
                        pushErrorToSentry({
                            category: 'Authentication',
                            payload: { email: email, password: data.password, oobCode: oobCode },
                            error: error_1,
                            message: 'パスワードの変更に失敗しました',
                        });
                        return [3 /*break*/, 4];
                    case 4:
                        if (!email)
                            return [2 /*return*/];
                        return [4 /*yield*/, signInWithEmailAndPassword(email, data.password)];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }, function (error) {
            console.error(error);
            invalidNotification();
        })();
    };
    return {
        submit: submit,
        control: control,
    };
};
