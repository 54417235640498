var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useGetKotobukUser } from './useGetKotobukUser';
import { createKotobukApiClient } from '../../aspida';
import { useSnackbar } from '../../hooks';
export var useUploadHealthCheckFile = function (_a) {
    var atndUser = _a.atndUser, file = _a.file, onUploaded = _a.onUploaded, uploadType = _a.uploadType, uploaderId = _a.uploaderId;
    var openSnackbar = useSnackbar().openSnackbar;
    var getKotobukUser = useGetKotobukUser().getKotobukUser;
    // TODO: 連携機能実装時に別Hookに切り出す
    var createOrGetUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var user, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(atndUser === null || atndUser === void 0 ? void 0 : atndUser.atnd_user_id))
                        return [2 /*return*/, undefined];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getKotobukUser(atndUser.atnd_user_id)];
                case 2:
                    user = _b.sent();
                    return [2 /*return*/, user];
                case 3:
                    _a = _b.sent();
                    return [2 /*return*/, createKotobukApiClient()
                            .user.$post({
                            body: atndUser,
                        })
                            .catch(function () {
                            openSnackbar('kotobukのユーザー作成に失敗しました', 'error');
                            return undefined;
                        })];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var uploadFile = function (user) { return __awaiter(void 0, void 0, void 0, function () {
        var api, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!uploaderId || !file) {
                        openSnackbar('必要な情報が不足しています。', 'error');
                        return [2 /*return*/];
                    }
                    api = createKotobukApiClient();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.healthcheck.files.upload.$post({
                            body: {
                                user_id: user === null || user === void 0 ? void 0 : user.id,
                                file: file,
                                uploader_category: 'clinic',
                                uploader_id: uploaderId,
                            },
                            config: {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            },
                        })];
                case 2:
                    _a.sent();
                    onUploaded === null || onUploaded === void 0 ? void 0 : onUploaded();
                    openSnackbar('アップロードが完了しました', 'success');
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    openSnackbar('アップロードに失敗しました', 'error');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleUpload = function () { return __awaiter(void 0, void 0, void 0, function () {
        var user;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(uploadType === 'pdf' && atndUser)) return [3 /*break*/, 4];
                    return [4 /*yield*/, createOrGetUser()];
                case 1:
                    user = _a.sent();
                    if (!user) return [3 /*break*/, 3];
                    return [4 /*yield*/, uploadFile(user)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [3 /*break*/, 6];
                case 4:
                    if (!(uploadType !== 'pdf')) return [3 /*break*/, 6];
                    return [4 /*yield*/, uploadFile(undefined)];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return {
        handleUploadHealthCheckFile: handleUpload,
    };
};
