import { FirebaseError } from 'firebase/app';
export var getSignupErrorMessage = function (error) {
    if (error instanceof FirebaseError) {
        switch (error.code) {
            case 'auth/email-already-in-use':
                return 'このメールアドレスは既に使用されています';
            case 'auth/invalid-email':
                return 'メールアドレスの形式が正しくありません';
            case 'auth/operation-not-allowed':
                return 'このメールアドレスは使用できません';
            case 'auth/weak-password':
                return 'パスワードが簡単すぎます';
            case 'auth/internal-error':
                return '認証サーバーで予期しないエラーが発生しました';
            default:
                return '登録に失敗しました';
        }
    }
    else {
        return '登録に失敗しました';
    }
};
export var getLoginErrorMessage = function (error) {
    console.log(error);
    if (error instanceof FirebaseError) {
        console.log(error.code);
        switch (error.code) {
            case 'auth/invalid-email':
                return 'メールアドレスの形式が正しくありません';
            case 'auth/user-disabled':
                return 'このユーザーは無効です';
            case 'auth/user-not-found':
                return 'ユーザーが見つかりません';
            case 'auth/wrong-password':
                return 'パスワードが間違っています';
            case 'auth/invalid-credential':
                return 'メールアドレスまたはパスワードが間違っています';
            case 'auth/too-many-requests':
                return 'ログイン試行が多すぎます。時間をおいて再度お試しください';
            case 'auth/internal-error':
                return '認証サーバーで予期しないエラーが発生しました';
            default:
                return 'ログインに失敗しました';
        }
    }
    else if (error instanceof Error) {
        return error.message;
    }
    else {
        return 'ログインに失敗しました';
    }
};
export var getUpdateEmailErrorMessage = function (error) {
    if (error instanceof FirebaseError) {
        console.error('メールアドレス変更エラー:', error.code);
        switch (error.code) {
            case 'auth/wrong-password':
                return 'パスワードが間違っています';
            case 'auth/email-already-in-use':
                return 'このメールアドレスは既に使用されています';
            case 'auth/invalid-email':
                return 'メールアドレスの形式が正しくありません';
            case 'auth/requires-recent-login':
                return '再ログインが必要です';
            case 'auth/internal-error':
                return '認証サーバーで予期しないエラーが発生しました';
            default:
                return 'メールアドレスの変更に失敗しました';
        }
    }
    else {
        return 'メールアドレスの変更に失敗しました';
    }
};
export var getEmailVerificationErrorMessage = function (error) {
    if (error instanceof FirebaseError) {
        console.error('メールアドレス認証エラー:', error.code);
        switch (error.code) {
            case 'auth/invalid-action-code':
                return 'URLが不正です';
            case 'auth/expired-action-code':
                return 'URLの有効期限が切れています';
            case 'auth/user-disabled':
                return 'このユーザーは無効です';
            case 'auth/user-not-found':
                return 'ユーザーが見つかりません';
            case 'auth/internal-error':
                return '認証サーバーで予期しないエラーが発生しました';
            case 'auth/requires-recent-login':
                return '再ログインが必要です';
            default:
                return 'メールアドレスの認証に失敗しました';
        }
    }
    else {
        return 'メールアドレスの認証に失敗しました';
    }
};
